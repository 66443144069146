import autoComplete from "@tarekraafat/autocomplete.js";

/**
 * Wrapper functions for @tarekraafat/autocomplete.js to define default options
 *
 * @param {*} inputSelector
 * @param {*} autoCompleteOptions
 * @returns
 */

export default function (inputSelector, autoCompleteOptions) {
  const autoCompleteJS = new autoComplete({
    selector: inputSelector, // Input field selector              | (Optional)
    observer: true, // Input field observer | (Optional)
    threshold: 3, // Min. Chars length to start Engine | (Optional)
    debounce: 300, // Post duration for engine to start | (Optional)
    searchEngine: "strict", // Search Engine type/mode           | (Optional)
    resultsList: {
      // Rendered results list object      | (Optional)
      destination: inputSelector,
      position: "afterend",
      element: "ul"
    },
    maxResults: 5,
    highlight: true,
    resultItem: {
      // Rendered result item            | (Optional)
      content: (data, source) => {
        source.innerHTML = data.match;
      },
      element: "li"
    },
    ...autoCompleteOptions
  });
  return autoCompleteJS;
}
