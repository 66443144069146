/**
 *
 * See here: https://github.com/Johann-S/bs-custom-file-input
 */

import bsCustomFileInput from "bs-custom-file-input";

(function () {
  document.addEventListener("DOMContentLoaded", function () {
    bsCustomFileInput.init();
  });
})();
