/**
 *
 * --------------------------------------------------------------------------
 * WKO InHouse (v0.1.0): lazy-svg-sprite.js
 * Licensed under MITT
 * --------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const VERSION = "0.1.0";
const DATA_ATTR = "data-lazy-svg-sprite";

const Selector = {
  DATA_LAZY: `[${DATA_ATTR}]`
};

/**
 * We should lazy load the svg sprite only if we add a div with data attributes
 * Like bootstrap we can use this data attributes as an API if we want to lazy load a svg sprite
 *
 * Developer Experience
 *
 *  <div data-lazy-svg-sprite="/docs/{{ .Site.Params.docs_version }}/dist/assets/icons/wko/sprite.symbol.svg"></div>
 *
 */

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

export default class LazySVGSprite {
  constructor(svgUrl) {
    this.svgUrl = svgUrl;
  }

  load() {
    const ajax = new XMLHttpRequest();
    ajax.open("GET", this.svgUrl, true);
    ajax.send();
    ajax.addEventListener("load", () => {
      const response = ajax.responseText;
      if (response.match(/<\s*script/)) {
        console.error(
          `Error: Response from ${this.svgUrl} uses a <script> tag. Not inserted into DOM`
        );
      } else if (ajax.status === 200) {
        const div = document.createElement("div");
        div.setAttribute("style", "display: none !important;");
        div.innerHTML = response;
        document.body.insertBefore(div, document.body.childNodes[0]);
      } else {
        console.error(`Error fetching ${this.svgUrl}: ${response}`);
      }
    });
  }

  static get VERSION() {
    return VERSION;
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const lazySVGSprites = Array.prototype.slice
    .call(document.querySelectorAll(Selector.DATA_LAZY))
    .map((element) => {
      const svgUrl = element.getAttribute(DATA_ATTR);
      return new LazySVGSprite(svgUrl);
    });
  lazySVGSprites.forEach((lazy) => lazy.load());
});
