import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

/**
 *
 * --------------------------------------------------------------------------
 * WKO InHouse (v0.1.0): datepicker.js
 * --------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const VERSION = "0.1.0";
const DATA_ATTR = "data-flatpickr";

const Selector = {
  DATA_FLATPICKR: `[${DATA_ATTR}]`
};

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

export default class Datepicker {
  constructor(inputEl) {
    this.inputEl = inputEl;
  }

  initialize() {
    if (this.inputEl && this.inputEl.dataset.flatpickr) {
      let options = {};
      const flatpickrDataOption = this.inputEl.dataset.flatpickr;

      try {
        options = JSON.parse(flatpickrDataOption);
      } catch {
        options = {};
      }

      flatpickr(this.inputEl, { locale: German, ...options });
    }
  }

  static get VERSION() {
    return VERSION;
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const inputElements = Array.prototype.slice
    .call(document.querySelectorAll(Selector.DATA_FLATPICKR))
    .map((element) => {
      return new Datepicker(element);
    });
  inputElements.forEach((datepicker) => datepicker.initialize());
});
