/**
 * When content is loaded, we query all bootstrap dropdowns with
 * the data-dropdown-push="#id|.class" attribute to listen to their
 * shown and hidden events.
 *
 * See here: https://v5.getbootstrap.com/docs/5.0/components/dropdowns/#events
 */

(function () {
  document.addEventListener("DOMContentLoaded", function () {
    Array.prototype.slice
      .call(document.querySelectorAll(".dropdown[data-dropdown-push]"))
      .forEach((element) => {
        element.addEventListener("shown.bs.dropdown", function () {
          const dropdownMenu = this.querySelectorAll(".dropdown-menu");

          const height = dropdownMenu.length
            ? dropdownMenu[0].getBoundingClientRect().height
            : 0;

          if (height) {
            Array.prototype.slice
              .call(document.querySelectorAll(element.dataset.dropdownPush))
              .forEach((toPushElement) => {
                toPushElement.style.transform = `translate3d(0, ${height}px, 0)`;
              });
          }
        });
        element.addEventListener("hidden.bs.dropdown", function () {
          Array.prototype.slice
            .call(document.querySelectorAll(element.dataset.dropdownPush))
            .forEach((toPushElement) => {
              toPushElement.style.transform = `translateY(0px)`;
            });
        });
      });
  });
})();
