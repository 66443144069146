import Choices from "choices.js";

function initChoicesDataAttributes() {
  const elements = document.querySelectorAll("[data-wk-choices]");

  const defaultConfig = {
    loadingText: "Lade...",
    noResultsText: "Keine Ergebnisse",
    noChoicesText: "Keine Auswahlmöglichkeit",
    itemSelectText: "Auswählen"
  };

  for (const element of elements) {
    let dataConfig = {};
    try {
      dataConfig = JSON.parse(element.dataset.wkChoices);
    } catch {
      // do nothing
    }

    const config = {
      ...defaultConfig,
      ...dataConfig
    };

    // eslint-disable-next-line no-new
    new Choices(element, config);
  }
}

document.addEventListener("DOMContentLoaded", function () {
  initChoicesDataAttributes();
});

export default Choices;
